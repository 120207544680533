import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from '../Components/Nav/NavBar';

const PrivateRoute = () => {
    const { token } = useSelector((state) => state.auth);

    if (!token) {
       
        return <Navigate to="/" />;
    }

   
    return (
        <>
            <Navbar />
            <Outlet />
        </>
    );
};
export default PrivateRoute;