import React, { useState } from 'react';
import { Box, Button, Typography, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../API/authAPI';

const ResetPasswordPage = () => {
    const [open, setOpen] = useState(true);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const navigate = useNavigate();  // Initialize useNavigate

    const handleResetPassword = async () => {
        try {
            await resetPassword({ username, email, newPassword });
            alert('Password reset successfully!');
            setOpen(false);
            navigate('/');  // Redirect to login after resetting the password
        } catch (error) {
            console.error('Failed to reset password', error);
            alert('Failed to reset password. Please check your credentials and try again.');
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Reset Password
            </Typography>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Reset Your Password</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleResetPassword} color="primary">
                        Reset Password
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ResetPasswordPage;
