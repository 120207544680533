import React from 'react';
import { Grid, Box } from '@mui/material';
import VehicleTreeView from './TreeViewComp';

export default function EventManagementPage({ currentUser }) {
  return (
    <Grid container sx={{ mt: 10, height: 'calc(100vh - 80px)' }} spacing={2}>
      {/* Left side: TreeView */}
      <Grid 
        item 
        
        md={12} 
        
      >
        <Box sx={{ width: '100%', maxWidth: 400 }}>
          <VehicleTreeView  />
        </Box>
      </Grid>
      
    </Grid>
    
  );
}
