import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useFetchDeviceGroupsQuery } from '../../Features/API/apiSlice';
import FlagIcon from '@mui/icons-material/Flag';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CreateEventModal from './CreateEventModal';

const CustomTreeView = ({ currentUser }) => {
  const [expandedItems, setExpandedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { data: groupsResponse, error: groupsError, isLoading: groupsLoading } = useFetchDeviceGroupsQuery();
  const treeViewRef = useRef(null);

  const [flaggedVehicles, setFlaggedVehicles] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedGroupTitle, setSelectedGroupTitle] = useState(null);
  const [openEventModal, setOpenEventModal] = useState(false);

  useEffect(() => {
    const savedFlags = localStorage.getItem('flaggedVehicles');
    if (savedFlags) {
      setFlaggedVehicles(JSON.parse(savedFlags));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('flaggedVehicles', JSON.stringify(flaggedVehicles));
  }, [flaggedVehicles]);

  useEffect(() => {
  }, [groupsResponse]);

  useEffect(() => {
    if (selectedVehicle && selectedGroupTitle) {
      // Load details when selectedVehicle and selectedGroupTitle are set
    }
  }, [selectedVehicle, selectedGroupTitle]);

  if (groupsLoading) return <div>Loading...</div>;
  if (groupsError) return <div>Error fetching data</div>;

  const groupList = groupsResponse?.groups || [];

  const getItemId = (type, id) => `${type}-${id}`;

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const handleCollapseClick = () => {
    setExpandedItems([]);
  };

  const handleScrollToBottom = () => {
    if (treeViewRef.current) {
      treeViewRef.current.scrollTop = treeViewRef.current.scrollHeight;
    }
  };

  const handleScrollToTop = () => {
    if (treeViewRef.current) {
      treeViewRef.current.scrollTop = 0;
    }
  };

  const filteredGroups = groupList
    .map((group) => {
      const filteredVehicles = group.vehicles.filter((vehicle) =>
        vehicle.plate_number.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (group.title.toLowerCase().includes(searchQuery.toLowerCase()) || filteredVehicles.length > 0) {
        return {
          ...group,
          vehicles: filteredVehicles.length > 0 ? filteredVehicles : group.vehicles,
        };
      }
      return null;
    })
    .filter(Boolean);

  const handleFlagToggle = (vehicleId) => {
    setFlaggedVehicles(prev => {
      const currentState = prev[vehicleId] || 'grey';
      const nextState = {
        'grey': 'green',
        'green': 'yellow',
        'yellow': 'red',
        'red': 'grey'
      }[currentState];
      return { ...prev, [vehicleId]: nextState };
    });
  };

  const handleResetFlags = () => {
    setFlaggedVehicles({});
  };

  const handleMoreClick = (event, vehicle) => {
    setAnchorEl(event.currentTarget);
    setSelectedVehicle(vehicle);
    setSelectedGroupTitle(vehicle.group_title); // Ensure group title is set
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedVehicle(null);
  };

  const handleCreateEvent = () => {
    setOpenEventModal(true);
    
  };

  return (
    <Stack spacing={2}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          variant="outlined"
          placeholder="Search Groups or Vehicles..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="small"
          sx={{ flexGrow: 1 }}
        />
        <IconButton color="primary" onClick={handleResetFlags} sx={{ padding: '4px' }}>
          <FlagIcon />
        </IconButton>
        <IconButton color="primary" onClick={handleCollapseClick} sx={{ padding: '4px' }}>
          <UnfoldLessIcon />
        </IconButton>
        <IconButton color="primary" onClick={handleScrollToBottom} sx={{ padding: '4px' }}>
          <ArrowDownwardIcon />
        </IconButton>
        <IconButton color="primary" onClick={handleScrollToTop} sx={{ padding: '4px' }}>
          <ArrowUpwardIcon />
        </IconButton>
      </Box>
      <Box
        ref={treeViewRef}
        sx={{
          minHeight: 352,
          minWidth: 250,
          maxHeight: '80vh',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
            border: '2px solid #f1f1f1',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <SimpleTreeView
          aria-label="vehicle tree"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange}
        >
          {filteredGroups.length > 0 ? (
            filteredGroups.map((group) => (
              <TreeItem
                key={getItemId('group', group.group_id)}
                itemId={getItemId('group', group.group_id)}
                label={<span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{group.title}</span>}
              >
                {group.vehicles.map((vehicle) => (
                  <TreeItem
                    key={getItemId('vehicle', vehicle.plate_number)}
                    itemId={getItemId('vehicle', vehicle.plate_number)}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <FlagIcon 
                            sx={{ 
                              mr: 1, 
                              color: flaggedVehicles[vehicle.plate_number] || 'grey',
                              cursor: 'pointer'
                            }} 
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFlagToggle(vehicle.plate_number);
                            }}
                          />
                          <span style={{ fontWeight: 'bold', fontSize: '1em' }}>
                            {vehicle.plate_number} - {vehicle.plan}
                          </span>
                        </Box>
                        <IconButton
                          onClick={(e) => handleMoreClick(e, vehicle)}
                          size="small"
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Box>
                    }
                  >
                    {vehicle.devices.map((device) => (
                      <TreeItem
                        key={getItemId('device', device.device_id)}
                        itemId={getItemId('device', device.device_id)}
                        label={
                          <span style={{ fontWeight: 'normal', fontSize: '0.9em' }}>
                            {device.device_type}
                          </span>
                        }
                      >
                        <TreeItem
                          key={getItemId('device-id', device.device_id)}
                          itemId={getItemId('device-id', device.device_id)}
                          label={<span style={{ fontSize: '0.8em' }}>{`Device ID: ${device.device_id}`}</span>}
                        />
                      </TreeItem>
                    ))}
                  </TreeItem>
                ))}
              </TreeItem>
            ))
          ) : (
            <Box sx={{ padding: 2 }}>
              <span>No results found.</span>
            </Box>
          )}
        </SimpleTreeView>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => {/* Edit Vehicle logic */}}>Edit Vehicle</MenuItem>
        <MenuItem onClick={handleCreateEvent}>Create Event</MenuItem>
        <MenuItem onClick={() => {/* New Test logic */}}>New Test</MenuItem>
      </Menu>
      {openEventModal && (
        <CreateEventModal
          open={openEventModal}
          onClose={() => setOpenEventModal(false)}
          vehicle={selectedVehicle}
          groupTitle={selectedGroupTitle}
        />
      )}
    </Stack>
  );
};

export default CustomTreeView;
