import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Features/Authentication/authSlice';
import { apiSlice } from './Features/API/apiSlice'; // Import your apiSlice

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [apiSlice.reducerPath]: apiSlice.reducer, // Add the apiSlice reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware), // Add the apiSlice middleware
});

export default store;
