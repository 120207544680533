// src/Features/API/apiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for your Django backend
const BASE_URL = 'http://localhost:8000/api/';

// Function to get the stored authentication token
const getToken = () => localStorage.getItem('token'); // Retrieves the token from local storage

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Token ${token}`); // Ensure the token is correctly set
      }
      return headers;
    },
  }),
  tagTypes: ['DeviceGroup', 'Vehicle', 'Device', 'Alert', 'Event'],  // Added 'Vehicle' for vehicle data
  endpoints: (builder) => ({
    fetchDeviceGroups: builder.query({
      query: () => 'device-groups/',
      providesTags: ['DeviceGroup'],
    }),
    fetchVehiclesForGroups: builder.query({
      query: () => 'vehicles/',
      providesTags: ['Vehicle'],
    }),
    fetchDevicesForVehicles: builder.query({
      query: () => 'devices/',
      providesTags: ['Device'],
    }),
    fetchAlerts: builder.query({
      query: () => 'alerts/',
      providesTags: ['Alert'],
    }),
    fetchAlertsByDevice: builder.query({
      query: (device_id) => `alerts/device/${device_id}/`,
      providesTags: ['Alert'],
    }),
    updateClient: builder.mutation({
      query: ({ group_id, ...data }) => ({
        url: `device-groups/${group_id}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['DeviceGroup'],
    }),
    deleteClient: builder.mutation({
      query: (group_id) => ({
        url: `device-groups/${group_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DeviceGroup'],
    }),
    moveDeviceToGroup: builder.mutation({
      query: ({ device_id, new_group_id }) => ({
        url: `move-device/${device_id}/`,
        method: 'POST',
        body: { new_group_id },
      }),
      invalidatesTags: ['DeviceGroup', 'Device'],
    }),
    createEvent: builder.mutation({
      query: (eventData) => ({
        url: '/events/',
        method: 'POST',
        body: eventData,
      }),
      invalidatesTags: ['Event'],
    }),
  }),
  
  
});

export const {
  useFetchDeviceGroupsQuery,
  useFetchVehiclesForGroupsQuery,
  useFetchDevicesForVehiclesQuery,  // New hook for fetching devices
  useFetchAlertsQuery,
  useFetchAlertsByDeviceQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useMoveDeviceToGroupMutation,  // New hook for moving devices
  useCreateEventMutation,
} = apiSlice;
