import React from 'react';
import SideNav from './SideNav';
import Navbar from './NavBar';

const SideNavLayout = ({ children }) => {
    return (
      <div style={{ display: 'flex' }}>
        <Navbar />
        <SideNav />
        <main style={{ marginLeft: 60, paddingTop: 64, padding: 16 }}>
          {children}
        </main>
      </div>
    );
  };
  
  export default SideNavLayout;
