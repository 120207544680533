import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Icon for the button

const CustomCard = ({ title, description, icon, onClick }) => {
    return (
        <Card
            sx={{
                backgroundColor: '#FBFCF8', // White background
                borderRadius: '15px', // Rounded corners
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Soft shadow
                transition: 'transform 0.3s ease-in-out', // Smooth transition
                minHeight: '300px', // Customizable height
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between', // Evenly space the contents
                padding: '16px', // Padding inside the card
                '&:hover': {
                    transform: 'translateY(-10px)', // Pop-out effect on hover
                    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.15)', // Stronger shadow on hover
                    // cursor: 'pointer', // Show pointer on hover
                },
            }}
            
        >
            <CardContent sx={{ textAlign: 'center', paddingBottom: '0px' }}>
                {icon && <Box sx={{ mb: 2 }}>{icon}</Box>} {/* Icon at the top */}
                <Typography
                    variant="h5"
                    component="div"
                    sx={{
                        color: '#000', // Black text color
                        fontWeight: 'bold', // Bold text
                        mb: 1, // Margin bottom
                    }}
                >
                    {title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {description}
                </Typography>
            </CardContent>
            <Box sx={{ textAlign: 'center', marginTop: 'auto' }}> {/* Button at the bottom */}
                <Button
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    onClick={onClick}
                    sx={{
                        marginTop: '16px', // Add margin to separate from content above
                        backgroundColor: '#1976d2', // Primary color for the button
                    }}
                >
                    Go To
                </Button>
            </Box>
        </Card>
    );
};

export default CustomCard;
