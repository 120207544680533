import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useFetchAlertsQuery, useFetchDevicesForVehiclesQuery, useFetchDeviceGroupsQuery } from '../../Features/API/apiSlice';

const AlertsPage = () => {
  const { data: initialAlerts, error: alertsError, isLoading: isLoadingAlerts } = useFetchAlertsQuery();
  const { data: vehiclesResponse, error: vehiclesError } = useFetchDevicesForVehiclesQuery();
  const { data: groups, error: groupsError } = useFetchDeviceGroupsQuery();
  const [alerts, setAlerts] = useState(initialAlerts ? initialAlerts.map(alert => ({ id: alert.alert_id, ...alert })) : []);
  const [newAlertIds, setNewAlertIds] = useState([]);

  useEffect(() => {
    if (initialAlerts) {
      setAlerts(initialAlerts.map(alert => ({ id: alert.alert_id, ...alert }))); // Ensure `id` is set
    }
  }, [initialAlerts]);

  useEffect(() => {
    const eventSource = new EventSource('http://localhost:8000/api/alerts/stream/');
    
    // Debugging: Log when the connection is opened
    eventSource.onopen = () => console.log('EventSource connection opened.');

    eventSource.onmessage = (event) => {
      try {
        const newAlert = JSON.parse(event.data);
        console.log('Received new alert:', newAlert);

        setAlerts((prevAlerts) => {
          const updatedAlert = {
            id: newAlert.alert_id,
            ...newAlert,
            timestamp: new Date(newAlert.timestamp).toLocaleString(),
            ignition: newAlert.ignition ? 'On' : 'Off',
          };

          // Check if the alert already exists
          const existingAlertIndex = prevAlerts.findIndex(alert => alert.id === updatedAlert.id);

          if (existingAlertIndex !== -1) {
            // If it exists, update it
            const updatedAlerts = [...prevAlerts];
            updatedAlerts[existingAlertIndex] = updatedAlert;
            return updatedAlerts;
          } else {
            // If it doesn't exist, add it to the beginning
            return [updatedAlert, ...prevAlerts];
          }
        });

        setNewAlertIds((prevIds) => [newAlert.alert_id, ...prevIds]);
      } catch (error) {
        console.error('Failed to parse incoming alert:', error);
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource encountered an error:', error);
      eventSource.close();  // Close the connection on error
    };

    return () => {
      eventSource.close();
      console.log('EventSource connection closed.');
    };
  }, []);

  const columns = [
    { field: 'timestamp', headerName: 'Timestamp', width: 200 },
    { field: 'plate_number', headerName: 'Registration', width: 150 },
    { field: 'message', headerName: 'Alert Type', width: 150 },
    { field: 'device_id', headerName: 'Device ID', width: 150 },
    { field: 'client', headerName: 'Client', width: 200 },
    { field: 'geofence_name', headerName: 'Geofence', width: 150 },
    { field: 'speed', headerName: 'Speed', width: 100 },
    { field: 'type', headerName: 'Alert Config', width: 150 },
    { field: 'name', headerName: 'Alert Name', width: 150 },
    { field: 'address', headerName: 'Address', width: 150 },
    { field: 'ignition', headerName: 'Ignition', width: 150 },
  ];

  const getRowClassName = (params) => {
    const alertName = params.row.name.toLowerCase();
    if (/ignition off/i.test(alertName) || /security box/i.test(alertName) || /panic/i.test(alertName)) {
      return 'alert-p1'; // P1 alerts
    } else if (/zone in/i.test(alertName) || /zone out/i.test(alertName) || /overspeed/i.test(alertName)) {
      return 'alert-p2'; // P2 alerts
    } else if (/crash/i.test(alertName) || /door/i.test(alertName) || /ignition on/i.test(alertName)) {
      return 'alert-p3'; // P3 alerts
    }
    return '';
  };

  if (isLoadingAlerts) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (alertsError || vehiclesError || groupsError) {
    return <Typography color="error">Error fetching data: {alertsError?.message || vehiclesError?.message || groupsError?.message}</Typography>;
  }

  return (
    <Paper sx={{ borderRadius: '16px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
      <Box sx={{ p: 3, mt: 10 }}>
        <Typography variant="h4" gutterBottom>
          Alerts
        </Typography>
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={alerts}
            columns={columns}
            pageSize={10}
            getRowClassName={getRowClassName}
            sx={{
              '& .MuiDataGrid-virtualScroller': {
                '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '10px',
                  border: '2px solid #f1f1f1',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#555',
                },
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              '& .MuiDataGrid-row.alert-p1': {
                bgcolor: 'error.light',
                color: 'white',
                '&:hover': {
                  bgcolor: 'error.main',
                  color: 'black',
                },
              },
              '& .MuiDataGrid-row.alert-p2': {
                bgcolor: 'warning.light',
                color: 'white',
                '&:hover': {
                  bgcolor: 'warning.main',
                  color: 'black',
                },
              },
              '& .MuiDataGrid-row.alert-p3': {
                bgcolor: 'info.light',
                color: 'white',
                '&:hover': {
                  bgcolor: 'info.main',
                  color: 'black',
                },
              },
            }}
          />
        </div>
      </Box>
    </Paper>
  );
};

export default AlertsPage;
