import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../Features/Authentication/authSlice';
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    Link,
    Grid,
    Paper,
} from '@mui/material';
import FleetCam from '../Photos/FleetCam - Logo.png';
import LoginImage from '../Photos/Login_background.jpg'
const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { error } = useSelector((state) => state.auth);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login({ username, password }))
            .unwrap()
            .then((response) => {
                if (response.require_password_reset) {
                    navigate('/reset-password');
                } else {
                    navigate('/dashboard');
                }
            })
            .catch((err) => {
                console.error('Login failed:', err);
            });
    };

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            {/* Left side - login form as a card */}
            <Grid
                item
                xs={12}
                sm={9}
                md={8}
                component={Box}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ backgroundColor: 'white' }}
            >
                <Paper
                    elevation={6}
                    sx={{
                        padding: '40px',
                        borderRadius: '12px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: '400px',
                    }}
                >
                    <img src={FleetCam} alt="Company Logo" style={{ width: '60%', marginBottom: '20px' }} />
                    <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
                        Log in to your Account
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 3 }}>
                        Welcome back! Please log in to continue.
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Email"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <Box sx={{ mr: 1 }}>
                                        <i className="fas fa-envelope"></i>
                                    </Box>
                                ),
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <Box sx={{ mr: 1 }}>
                                        <i className="fas fa-lock"></i>
                                    </Box>
                                ),
                                endAdornment: (
                                    <Box sx={{ ml: 1 }}>
                                        <i className="fas fa-eye"></i>
                                    </Box>
                                ),
                            }}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        {error && (
                            <Typography color="error" variant="body2">
                                {error}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Log in
                        </Button>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Link href="/reset-password" variant="body2">
                                Forgot Password?
                            </Link>
                        </Box>
                    </Box>
                </Paper>
            </Grid>

            {/* Right side - Background image */}
            <Grid
                item
                xs={false}
                sm={3}
                md={4}
                sx={{
                    backgroundImage: `url(${LoginImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {/* Additional content on the right side (if needed) */}
            </Grid>
        </Grid>
    );
};

export default Login;