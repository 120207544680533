import React from 'react';
import { Grid, Box, Typography, Paper, useTheme } from '@mui/material';
import VehicleTreeView from './TreeViewComp';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';

export default function BureauLandingPage() {
  const theme = useTheme();

  // Dummy data for Bar Chart
  const barChartData = [
    { client: 'Client1', value: 400 },
    { client: 'Client2', value: 300 },
    { client: 'Client3', value: 500 },
    { client: 'Client4', value: 200 },
    { client: 'Client5', value: 600 },
  ];

  // Dummy data for Pie Chart
  const pieChartData = [
    { label: 'Group A', value: 400 },
    { label: 'Group B', value: 300 },
    { label: 'Group C', value: 200 },
    { label: 'Group D', value: 100 },
  ];

  return (
    <Box sx={{ p: 3, mt: 8 }}>
      <Typography variant="h4" gutterBottom>
        Bureau Dashboard
      </Typography>
      <Typography variant="body1" color="textSecondary" paragraph>
        Welcome to the Bureau Dashboard.
      </Typography>

      <Grid container spacing={3}>
        {/* Left side: TreeView */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Clients and Vehicles
            </Typography>
            <Box sx={{ height: 'calc(100vh - 250px)' }}>
              <VehicleTreeView />
            </Box>
          </Paper>
        </Grid>
        
        {/* Right side: Charts */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            {/* Bar Chart */}
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Client Alerts
                </Typography>
                <Box sx={{ height: 300, width: '100%' }}>
                  <BarChart
                    dataset={barChartData}
                    xAxis={[{ scaleType: 'band', dataKey: 'client' }]}
                    series={[{ dataKey: 'value', label: 'Alerts' }]}
                    colors={[theme.palette.primary.main]}
                    layout="vertical"
                  />
                </Box>
              </Paper>
            </Grid>
            
            {/* Pie Chart */}
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Group Distribution
                </Typography>
                <Box sx={{ height: 300, width: '100%' }}>
                  <PieChart
                    series={[
                      {
                        data: pieChartData,
                        innerRadius: 30,
                        outerRadius: 100,
                        paddingAngle: 2,
                        cornerRadius: 5,
                        startAngle: -90,
                        endAngle: 270,
                      },
                    ]}
                    colors={[
                      theme.palette.primary.main,
                      theme.palette.secondary.main,
                      theme.palette.error.main,
                      theme.palette.warning.main,
                    ]}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
