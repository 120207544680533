// src/Components/API/userGroupsAPI.js
import api from './api';  // Import the centralized Axios instance

// Function to get all user groups
export const getUserGroups = async () => {
    const response = await api.get('/auth/groups/');
    return response.data;
};

// Function to create a new user group
export const createUserGroup = async (groupData) => {
    const response = await api.post('/auth/groups/', groupData);
    return response.data;
};

// Function to update a user group
export const updateUserGroup = async (groupId, groupData) => {
    const response = await api.put(`/auth/groups/${groupId}/`, groupData);
    return response.data;
};

// Function to delete a user group
export const deleteUserGroup = async (groupId) => {
    await api.delete(`/auth/groups/${groupId}/`);
};

// Function to get all permissions
export const getPermissions = async () => {
    const response = await api.get('/auth/permissions/');
    return response.data;
};
