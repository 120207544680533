import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const ManageCard = ({ title, icon, manageLink, groupLink, manageTitle, groupTitle }) => {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box display="flex" alignItems="center" mb={2}>
          {icon}
          <Typography variant="h6" component="div" sx={{ ml: 1 }}>
            {title}
          </Typography>
        </Box>
        <Button component={RouterLink} to={manageLink} variant="outlined" fullWidth sx={{ mb: 1 }}>
          {manageTitle}
        </Button>
        {groupLink && (
          <Button component={RouterLink} to={groupLink} variant="outlined" fullWidth>
            {groupTitle}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default ManageCard;
