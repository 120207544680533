import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../Components/LoginPage/Login';
import Dashboard from '../Components/AdminDashBoard/LandingPage';
import SettingsPage from '../Components/AdminDashBoard/SettingsPage';
import PrivateRoute from './PrivateRoute';
import ManageUsers from '../Components/AdminDashBoard/ManageUsers';
import ResetPasswordPage from '../Components/AdminDashBoard/ResetPasswordPage';
import UserGroups from '../Components/AdminDashBoard/UserGroups';
import BureauLandingPage from '../Components/BureauPortal/Landing';
import SideNavLayout from '../Components/Nav/SideNavLayout';
import ManageClients from '../Components/AdminDashBoard/ManageClients';
import ManageVehicles from '../Components/AdminDashBoard/ManageVehicles';
import ManageDevices from '../Components/AdminDashBoard/ManageDevices';
import AlertsPage from '../Components/BureauPortal/AlertsPage';
import EventManagementPage from '../Components/BureauPortal/EventManagementPage';





const Router = () => (
    <Routes>
        {/* Public Route */}
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />

        {/* Private Routes Layout */}
        <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/manage-users" element={<ManageUsers />} />
            <Route path="/user-groups" element={<UserGroups />} />
            <Route path="/manage-clients" element={<ManageClients />} />
            <Route path="/manage-vehicles" element={<ManageVehicles />} />
            <Route path="/manage-devices" element={<ManageDevices />} />
            <Route path="/alerts" element={<SideNavLayout><AlertsPage /></SideNavLayout>} />
            <Route 
                path="/bureau-dashboard" 
                element={
                    <SideNavLayout>
                        <BureauLandingPage />
                    </SideNavLayout>
                } 
            />
            <Route path="/event-management" element={<SideNavLayout><EventManagementPage /></SideNavLayout>} />
        </Route>
           

        
    </Routes>
);

export default Router;  
