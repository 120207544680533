// src/Components/API/userManagementAPI.js
import api from './api';  // Import the centralized Axios instance

// Function to get all users
export const getUsers = async () => {
    const response = await api.get('/auth/users/');
    return response.data;
};

// Function to create a new user
export const createUser = async (userData) => {
    const response = await api.post('/auth/users/', userData);
    return response.data;
};

// Function to update a user
export const updateUser = async (userId, userData) => {
    const response = await api.put(`/auth/users/${userId}/`, userData);
    return response.data;
};

// Function to delete a user
export const deleteUser = async (userId) => {
    await api.delete(`/auth/users/${userId}/`);
};

// Function to get all groups
export const getGroups = async () => {
    const response = await api.get('/auth/groups/');
    return response.data;
};

// Function to create a new group
export const createGroup = async (groupData) => {
    const response = await api.post('/auth/groups/', groupData);
    return response.data;
};

// Function to update a group
export const updateGroup = async (groupId, groupData) => {
    const response = await api.put(`/auth/groups/${groupId}/`, groupData);
    return response.data;
};

// Function to delete a group
export const deleteGroup = async (groupId) => {
    await api.delete(`/auth/groups/${groupId}/`);
};

// Function to get all permissions
export const getPermissions = async () => {
    const response = await api.get('/auth/permissions/');
    return response.data;
};

// Function to create a new permission
export const createPermission = async (permissionData) => {
    const response = await api.post('/auth/permissions/', permissionData);
    return response.data;
};

// Function to update a permission
export const updatePermission = async (permissionId, permissionData) => {
    const response = await api.put(`/auth/permissions/${permissionId}/`, permissionData);
    return response.data;
};

// Function to delete a permission
export const deletePermission = async (permissionId) => {
    await api.delete(`/auth/permissions/${permissionId}/`);
};
