import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'Roboto Flex, Arial, sans-serif',
    },
    components: {
        MuiCssBaseline: {
          styleOverrides: `
            *::-webkit-scrollbar {
              width: 0.4em;
              height: 0.6em;
            }
            *::-webkit-scrollbar-track {
              background: #FAFAFA;
              border-radius: 8px;
            }
            *::-webkit-scrollbar-thumb {
              background-color: #C8C8C8;
              border-radius: 8px;
            }
            *::-webkit-scrollbar-thumb:hover {
              background: #aaa;
            }
          `,
        },
    },
});

export default theme;
