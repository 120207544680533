import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useCreateEventMutation } from '../../Features/API/apiSlice';

const eventTypes = [
  'Passenger Door',
  'Safe Door',
  'Driver Door',
  'Geofence Exit',
  'Geofence Entry',
  'Illegal Zone Entry',
  'Unauthorized Stop',
  'Over speeding',
  'Battery Tamper',
  'Security Box Tamper',
  'Panic Alarm',
  'Vehicle Breakdown',
  'Smoking in Cab',
  'Passenger(s) in Vehicle',
  'Seatbelt Violation',
  'Cellphone Usage',
  'Reckless Driving',
  'Camera Tampering',
  'Camera Obstruction',
  'Illegal Activity',
];

const CreateEventModal = ({ open, onClose, vehicle, groupTitle }) => {
  const [createEvent] = useCreateEventMutation();
  const [eventData, setEventData] = useState({
    client_name: groupTitle,
    plate_number: vehicle ? vehicle.plate_number : '',
    event_type: '',
    latitude: '',
    longitude: '',
    media_download_link: '',
    speed: '',
    images: [],
    agent_comments: '',
    prim_contact: false,
    sec_contact: false,
    emergency_contact: false,
    address: '',
    driver: vehicle ? vehicle.driver : '',
    passenger_count: '',
  });

  useEffect(() => {
    if (vehicle && groupTitle) {
      setEventData(prevData => ({
        ...prevData,
        client_name: groupTitle,
        plate_number: vehicle.plate_number,
        driver: vehicle.driver || '',
      }));
    }
  }, [vehicle, groupTitle]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEventData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files).slice(0, 4);
    setEventData(prevData => ({ ...prevData, images: files }));
  };

  const handleSubmit = async () => {
    try {
      // Create a new FormData object to send form data as multipart/form-data
      const formData = new FormData();
  
      // Append regular form fields to formData
      formData.append('client_name', eventData.client_name);
      formData.append('plate_number', eventData.plate_number);  // Ensure this is appended correctly
      formData.append('event_type', eventData.event_type);
      formData.append('latitude', eventData.latitude);
      formData.append('longitude', eventData.longitude);
      formData.append('media_download_link', eventData.media_download_link);
      formData.append('speed', eventData.speed);
      formData.append('agent_comments', eventData.agent_comments);
      formData.append('prim_contact', eventData.prim_contact);
      formData.append('sec_contact', eventData.sec_contact);
      formData.append('emergency_contact', eventData.emergency_contact);
      formData.append('address', eventData.address);
      formData.append('driver', eventData.driver);
      formData.append('passenger_count', eventData.passenger_count);
  
      // Append each image to formData directly as File objects
      eventData.images.forEach((file) => {
        formData.append('uploaded_images', file);  // Correct key 'uploaded_images' to match backend expectations
      });
  
      console.log('FormData payload being sent:', {
        plate_number: eventData.plate_number,
        files: formData.getAll('uploaded_images'),  // Ensure files are appended correctly
        formData,
      });
  
      // Send the request using the useCreateEventMutation hook
      const result = await createEvent(formData).unwrap();
      console.log('Event created successfully:', result);
      onClose();
    } catch (error) {
      console.error('Failed to create event:', error);
    }
  };

  if (!vehicle || !groupTitle) {
    return null; // Render nothing if the required props are not available
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create Event</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Client Name"
              name="client_name"
              value={eventData.client_name}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Vehicle Plate Number"
              name="plate_number"
              value={eventData.plate_number}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Event Type</InputLabel>
              <Select
                name="event_type"
                value={eventData.event_type}
                onChange={handleChange}
              >
                {eventTypes.map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Latitude"
              name="latitude"
              value={eventData.latitude}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Longitude"
              name="longitude"
              value={eventData.longitude}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Media Download Link"
              name="media_download_link"
              value={eventData.media_download_link}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Speed"
              name="speed"
              value={eventData.speed}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Passenger Count"
              name="passenger_count"
              value={eventData.passenger_count}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Upload Images (max 4)</Typography>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Agent Comments"
              name="agent_comments"
              value={eventData.agent_comments}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={eventData.prim_contact} onChange={handleChange} name="prim_contact" />}
              label="Primary Contact"
            />
            <FormControlLabel
              control={<Checkbox checked={eventData.sec_contact} onChange={handleChange} name="sec_contact" />}
              label="Secondary Contact"
            />
            <FormControlLabel
              control={<Checkbox checked={eventData.emergency_contact} onChange={handleChange} name="emergency_contact" />}
              label="Emergency Contact"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={eventData.address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Driver"
              name="driver"
              value={eventData.driver}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventModal;


