import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Breadcrumbs,
  Link as MuiLink,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Grid,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DevicesIcon from '@mui/icons-material/Devices';
import { Link as RouterLink } from 'react-router-dom';
import { useFetchDevicesForVehiclesQuery } from '../../Features/API/apiSlice';
import { useFetchDeviceGroupsQuery } from '../../Features/API/apiSlice';
import { useMoveDeviceToGroupMutation } from '../../Features/API/apiSlice';

const ManageDevices = () => {
  const { data: devicesResponse, error, isLoading } = useFetchDevicesForVehiclesQuery();
  const [devices, setDevices] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deviceToDelete, setDeviceToDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [newGroupId, setNewGroupId] = useState('');
  const { data: groupsResponse } = useFetchDeviceGroupsQuery();
  const [moveDeviceToGroup] = useMoveDeviceToGroupMutation();

  useEffect(() => {
    if (devicesResponse && devicesResponse.devices) {
      setDevices(devicesResponse.devices);
    }
  }, [devicesResponse]);

  const handleOpenDeleteDialog = (device) => {
    setDeviceToDelete(device);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeviceToDelete(null);
  };

  const handleConfirmDelete = async () => {
    // Your delete logic here
    handleCloseDeleteDialog();
  };

  const handleMoreClick = (event, device) => {
    setAnchorEl(event.currentTarget);
    setSelectedDevice(device);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedDevice(null);
  };

  const handleOpenMoveDialog = (device) => {
    setSelectedDevice(device);
    setMoveDialogOpen(true);
  };

  const handleCloseMoveDialog = () => {
    setMoveDialogOpen(false);
    setSelectedDevice(null);
    setNewGroupId('');
  };

  const handleConfirmMove = async () => {
    try {
      await moveDeviceToGroup({ device_id: selectedDevice.device_id, new_group_id: newGroupId });
      handleCloseMoveDialog();
    } catch (error) {
      alert('Failed to move device');
    }
  };

  const columns = [
    { field: 'device_id', headerName: 'Device ID', flex: 1 },
    { field: 'vehicle_name', headerName: 'Vehicle', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <Tooltip title="More actions">
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(event) => handleMoreClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl && selectedDevice?.id === params.row.id)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => handleOpenMoveDialog(params.row)}>
              <EditIcon sx={{ mr: 1 }} />
              Move to Another Group
            </MenuItem>
            <MenuItem onClick={() => handleOpenDeleteDialog(params.row)}>
              <DeleteIcon sx={{ mr: 1 }} />
              Delete
            </MenuItem>
          </Menu>
        </Box>
      ),
    },
  ];

  const rows = devices.map((device) => ({
    id: device.device_id,
    device_id: device.device_id,
    vehicle_name: device.vehicle ? device.vehicle.name : 'Unknown',
  }));

  return (
    <Paper sx={{ borderRadius: '16px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
      <Box sx={{ p: 3, mt: 7 }}>
        <Grid container spacing={3} alignItems="center" sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <Breadcrumbs aria-label="breadcrumb">
              <MuiLink component={RouterLink} to="/" color="inherit">
                Home
              </MuiLink>
              <MuiLink component={RouterLink} to="/dashboard" color="inherit">
                Dashboard
              </MuiLink>
              <MuiLink component={RouterLink} to="/settings" color="inherit">
                Settings
              </MuiLink>
              <Typography color="textPrimary">Manage Devices</Typography>
            </Breadcrumbs>
            <Typography variant="h4" sx={{ mt: 2 }}>
              Manage Devices
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DevicesIcon />}
            >
              Add Device
            </Button>
          </Grid>
        </Grid>

        <Paper elevation={3} sx={{ height: 600, width: '100%' }}>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{
                '& .MuiDataGrid-toolbarContainer': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  padding: 1,
                },
              }}
            />
          )}
        </Paper>

        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete device {deviceToDelete?.device_id}? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={moveDialogOpen} onClose={handleCloseMoveDialog}>
          <DialogTitle>Move Device to Another Group</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id="select-group-label">Select Group</InputLabel>
              <Select
                labelId="select-group-label"
                value={newGroupId}
                onChange={(e) => setNewGroupId(e.target.value)}
              >
                {groupsResponse?.groups.map((group) => (
                  <MenuItem key={group.group_id} value={group.group_id}>
                    {group.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMoveDialog}>Cancel</Button>
            <Button onClick={handleConfirmMove} color="primary">Move</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
};

export default ManageDevices;
