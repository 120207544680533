import React, { useState } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepContent,
  StepButton,
  StepConnector,
} from '@mui/material';

const steps = [
  'Basic client information',
  'Vehicle and device set up',
  'Drivers',
  'Bureau solution configuration',
  'Alerts and reports configuration',
  'Company Contact configuration',
  'Summary and finalization',
];

const AddClientWizard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep = isLastStep() && !allStepsCompleted()
      ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2, mb: 1, p: 2 }}>
        {allStepsCompleted() ? (
          <>
            <Typography variant="h6" gutterBottom>
              All steps completed - you&apos;re finished!
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </>
        ) : (
          <>
            <Typography variant="h6" gutterBottom>
              {`Step ${activeStep + 1}: ${steps[activeStep]}`}
            </Typography>
            {/* Render step-specific content here */}
            {/* For now, using placeholder content */}
            <Typography sx={{ mt: 2, mb: 1 }}>
              Content for {steps[activeStep]}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep !== steps.length - 1 && (
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                  Next
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button variant="contained" color="primary" onClick={handleComplete}>
                  Complete
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AddClientWizard;
