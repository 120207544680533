import axios from 'axios';

// Create a centralized Axios instance
const api = axios.create({
    baseURL: 'http://fleetcamagile:8000', // Base URL for your backend
});

// Add a request interceptor to include the token in all requests
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Token ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const loginUser = (credentials) => {
    return api.post('/auth/login/', credentials);
};

export default api;
