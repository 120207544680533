import React, { useState } from "react";
import { Drawer, List, ListItemIcon, ListItemText, ListItemButton, Collapse } from "@mui/material";
import { styled } from "@mui/system";
import SettingsIconOutlined from '@mui/icons-material/SettingsOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountCircleIconOutlined from '@mui/icons-material/AccountCircleOutlined';
import BuildIconOutlined from '@mui/icons-material/BuildOutlined';
import DirectionsCarIconOutlined from '@mui/icons-material/DirectionsCarOutlined';
import BusAlertIconOutlined from '@mui/icons-material/BusAlertOutlined';
import HandymanIconOutlined from '@mui/icons-material/HandymanOutlined';
import AddLocationAltIconOutlined from '@mui/icons-material/AddLocationAltOutlined';
import MinorCrashIconOutlined from '@mui/icons-material/MinorCrashOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Sidebar = styled(Drawer)(({ theme, open }) => ({
  width: open ? 240 : 60,
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter,
  }),
  overflowX: "hidden",
  zIndex: 1000,
  boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)", // Shadow on the right side
  "& .MuiDrawer-paper": {
    width: open ? 240 : 60,
    overflowX: "hidden",
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)", // Shadow on the right side
    zIndex: 1000,
  },
}));

const StyledListItemIcon = styled(ListItemIcon)({
  color: "#000", // Default icon color
  transition: "color 0.3s ease",
  "&:hover": {
    color: "#0078B7", // Change icon color on hover
  },
});

const SideNav = () => {
  const [open, setOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [technicalOpen, setTechnicalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleTechnicalClick = () => {
    setTechnicalOpen(!technicalOpen);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <Sidebar
      variant="permanent"
      open={open}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <List style={{ paddingTop: 80 }}>
        <ListItemButton onClick={() => navigate('/bureau-dashboard')}>
          <StyledListItemIcon>
            <DashboardCustomizeOutlinedIcon color={isActive('/bureau-dashboard') ? 'primary' : 'inherit'} />
          </StyledListItemIcon>
          {open && <ListItemText primary="Dashboard" />}
        </ListItemButton>
        
        <ListItemButton onClick={() => navigate('/alerts')}>
          <StyledListItemIcon>
            <NotificationsActiveOutlinedIcon color={isActive('/alerts') ? 'primary' : 'inherit'} />
          </StyledListItemIcon>
          {open && <ListItemText primary="Alert Monitoring" />}
        </ListItemButton>

        <ListItemButton onClick={() => navigate('/event-management')}>
          <StyledListItemIcon>
            <BusAlertIconOutlined color={isActive('/event-management') ? 'primary' : 'inherit'} />
          </StyledListItemIcon>
          {open && <ListItemText primary="Event Management" />}
        </ListItemButton>

        <ListItemButton onClick={handleTechnicalClick}>
          <StyledListItemIcon>
            <HandymanIconOutlined />
          </StyledListItemIcon>
          {open && <ListItemText primary="Technical" />}
          {open && (technicalOpen ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
        
        <Collapse in={technicalOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <StyledListItemIcon>
                <BuildIconOutlined />
              </StyledListItemIcon>
              {open && <ListItemText primary="Test Log" />}
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton>
          <StyledListItemIcon>
            <AddLocationAltIconOutlined />
          </StyledListItemIcon>
          {open && <ListItemText primary="Zone Management" />}
        </ListItemButton>

        <ListItemButton>
          <StyledListItemIcon>
              <MinorCrashIconOutlined />
          </StyledListItemIcon>
          {open && <ListItemText primary="Incident Management" />}
        </ListItemButton>

        <ListItemButton onClick={handleSettingsClick}>
          <StyledListItemIcon>
            <SettingsIconOutlined />
          </StyledListItemIcon>
          {open && <ListItemText primary="Settings" />}
          {open && (settingsOpen ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
        
        <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <StyledListItemIcon>
                <BuildIconOutlined />
              </StyledListItemIcon>
              {open && <ListItemText primary="General" />}
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <StyledListItemIcon>
                <AccountCircleIconOutlined />
              </StyledListItemIcon>
              {open && <ListItemText primary="Users" />}
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <StyledListItemIcon>
                <DirectionsCarIconOutlined />
              </StyledListItemIcon>
              {open && <ListItemText primary="Vehicles" />}
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </Sidebar>
  );
};

export default SideNav;
